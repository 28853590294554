import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

interface Props {
	className?: string;
	url?: string;
	opensNewTab?: boolean;
	cursorClass?: string;
	onClick?: () => void;
	children: React.ReactNode;
}

function Button({ children, url, opensNewTab = false, className, cursorClass, onClick }: Props) {
	const [classNames, setClassNames] = useState('');

	useEffect(() => {
		let classes = 'button';

		if (className !== undefined) {
			classes = `${classes} ${className}`;
		}

		if (cursorClass !== undefined) {
			classes = `${classes} button--no-cursor`;
		}

		setClassNames(classes);
	}, []);

	const handleMouseEnter = () => {
		const cursor = document.getElementById('cursor');
		cursor?.classList.add('cursor--button');
		if (cursorClass !== undefined) {
			cursor?.classList.add(`cursor--${cursorClass}`);
		}
	};

	const handleMouseLeave = () => {
		const cursor = document.getElementById('cursor');
		cursor?.classList.remove('cursor--button');
		if (cursorClass !== undefined) {
			cursor?.classList.remove(`cursor--${cursorClass}`);
		}
	};

	return (
		<button
			role="button"
			className={classNames}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={() => {
				if (url) {
					navigate(url);
					return;
				}

				if (onClick) {
					onClick();
					return;
				}
			}}
		>
			{children}
		</button>
	);
}

export default Button;
